export const optionsListServices = [
  { value: 'Web Development', label: 'Web Development' },
  { value: 'Search Engine Optimization', label: 'Search Engine Optimization' },
  { value: 'Data Science & Marketing Automation', label: 'Data Science & Marketing Automation' },
  { value: 'Paid Advertising', label: 'Paid Advertising' },
  { value: 'Branding', label: 'Branding' },
  { value: 'Social media marketing', label: 'Social Media Marketing' },
  { value: 'Content Production', label: 'Content Production' },
];
export const optionsListBusinessNiche = [
  { value: 'seo-ppc', label: 'SEO & PPC' },
  { value: 'web', label: 'Web development' },
  { value: 'managment', label: 'Reputation Management' },
  { value: 'social', label: 'Social Media' },
  { value: 'design', label: 'Graphic Design' },
  { value: 'marketing', label: 'Marketing Automation' },
];

import React, {useState} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import ConsultationForm from '../../components/Consultation/ConsultationFormBlack';
import { faqContent } from './content';
import Angle from '../Icons/Angle';
import { MOBILE, TABLET } from '../../constants/responsive';

const LetsTalkContainer = styled('section')`
  text-align: left;
  > p {
    max-width: 81rem;
    padding-top: 1.6rem;
    padding-bottom: 2.4rem;
  };
  @media (max-width:${MOBILE}) {
    display:flex;
    flex-direction:column;
    gap:8rem; 
    margin-top: 8rem;    
  } 
`;
const ContentContainer = styled('div')`
    display: flex;
    flex-direction:row;
    min-height: 57.5rem;
    @media (max-width:${TABLET}) {
      flex-direction:column;
      row-gap:12rem;
    }; 
    @media (max-width:${MOBILE}) {
    row-gap:0;
    margin: 0px auto;
    width:100%;    
    background:var(--bg-color-red);
    min-height: auto;    
    > h2 {
      padding: 1.6rem 1rem 2.4rem 1rem; 
      color:var(--main-color-white);     
    } 
    > p {
      padding: 0 1rem 1.6rem 1rem;
      color:var(--main-color-white);   
    }
    }
`;
const FormContainer = styled('div')`
    padding: 2.4rem;
    background-color:var(--bg-color-red);
    border-radius: 16px;
    ${'' /* flex:0 369px; */}
    width:100%;
    max-width:369px;
    z-index:1;
    height:417px;
    @media (max-width:${TABLET}) {
      align-self: center;
    }
    @media (max-width:${MOBILE}) {
      width:-webkit-fill-available;
      border-radius: 0;
      padding: 0;
      max-width:100%;
      padding-bottom: 1.6rem;
      form {
        width:95%!important;
        margin: auto;
      }
    }
`;
const FAQContainer = styled('div')`
    display: flex;
    flex-direction: column;
    gap: 4rem;
    flex:1;
    @media (max-width:${TABLET}) {
      gap: 2.4rem;;
    }
    @media (max-width:${MOBILE}) {
      padding: 0 1rem;
    }
`;
const FAQTitile = styled('div')`
    background-color: var(--main-color-black); 
    width:102%;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    margin-left: -15px;
    h2 {
        color: var(--main-color-white);
        margin:1.6rem 5.5rem;
        font-weight: 400;
        font-size: 20px;
        line-height: 140%;
    };
    @media (max-width:${TABLET}) {
      background-color: var(--main-color-white); 
      width:100%;      
      margin-left: 0;
      h2 {
        color: var(--main-color-black);
        margin:0;
        font-weight: 700;
        font-size: 3rem;
        line-height: 120%;
      };
    }
    @media (max-width:${MOBILE}) {
      h2 {
        font-size: 2rem;
        };
    };    
`;
const AccordionFAQContainer = styled('div')`
    margin-left: 2.3rem;
    @media (max-width:${TABLET}) {
      margin-left: 0;
    }
`;

function LetsTalkBlock({navigate}) {
  const [activeId, setActiveId] = useState(null);
  const handleClick = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  };
  return (
    <LetsTalkContainer>
        <h2 className='h2'>Let&prime;s talk</h2>
        <p>Our customer service is truly impeccable as we listen to all of our clients wants and needs.<br/>
            It’s time to make an impression that lasts, with Targetiva working alongside you for all of your digital marketing needs.</p>
        <ContentContainer>
          <FormContainer>
            <ConsultationForm navigate={navigate} />  
          </FormContainer>
          <FAQContainer>
            <FAQTitile>
              <h2 className='h2'>Frequently Asked Questions</h2>
            </FAQTitile>
              <AccordionFAQContainer>
                {faqContent.map(item => (
                  <AccordionFAQItem {...item} isActive={item.id === activeId} onClick={handleClick} key={item.id} />
                ))}
              </AccordionFAQContainer>
            </FAQContainer>
        </ContentContainer>        
    </LetsTalkContainer> 
  );
};
export default LetsTalkBlock;

const AccordionItem = styled('button')`
  position:relative;
  display: flex;
  justify-content: start;
  align-items: start;
  background: inherit;
  border: none;      
  cursor: pointer;
  padding: 8px 28px 8px 8px;
  border-radius: 16px;
  width: fit-content;
  text-align: left;
  outline: none;  
  gap: 8px;
  h3 {
      color:var(--main-color-black);
      max-width: 55rem;
  };
  span {
    margin-top: 5px;
    color:var(--main-color-black);
    @media (max-width:${MOBILE}) {
    margin-top: 1px;;
    };
  };   
`;  

const Panel = styled('div')` 
  background-color: var(--main-color-white);
  box-sizing: border-box; 
    p {
      margin: 24px 8px;
      }  
  `;
const active = css`
  background-color: var(--bg-color-red) !important;
  color:var(--main-color-white); 
  h3 {
    color:var(--main-color-white)!important;
    };
  span {   
    color:var(--main-color-white)!important;
  };  
             
  `;
const activeAngle = css`
  transform: rotate(180deg);
`;

function AccordionFAQItem({  question, answer, onClick, isActive, id }) {
  const handleClick = () => {
    onClick(id);
  };
  return (
    <div>
      <AccordionItem onClick={handleClick} type='button' className={isActive?active:undefined} >
         <span className={isActive?activeAngle:undefined}><Angle/></span><h3>{question}</h3>                 
      </AccordionItem>     
        <Panel className={isActive ? 'active-accordion' : 'hidden-accordion'}>
          <p className={isActive ? 'active-accordion-inner ' : 'hidden-accordion-inner'}>{answer}</p>
        </Panel>        
      </div>
    );
};

function LetsTalkBlockMobile({navigate}) {
  const [activeId, setActiveId] = useState(null);
  const handleClick = (id) => {
    if (activeId === id) {
        setActiveId(null);
      } else {
        setActiveId(id);
      };
    };
  return (
    <LetsTalkContainer>
      <ContentContainer>
        <h2 className='h2'>Let&prime;s talk</h2>
        <p>Our customer service is truly impeccable as we listen to all of our clients wants and needs.<br/>
          It’s time to make an impression that lasts, with Targetiva working alongside you for all of your digital marketing needs.</p>
         <FormContainer>
            <ConsultationForm navigate={navigate} />  
          </FormContainer>          
      </ContentContainer>        
      <FAQContainer>
        <FAQTitile>
          <h2 className='h2'>Frequently Asked Questions</h2>
        </FAQTitile>
        <AccordionFAQContainer>
          {faqContent.map(item => (
          <AccordionFAQItem {...item} isActive={item.id === activeId} onClick={handleClick} key={item.id} />
          ))}
        </AccordionFAQContainer>
      </FAQContainer> 
    </LetsTalkContainer> 
  );
};
export  {LetsTalkBlockMobile};
import {web , seo, brand, automotion, ppc, smm, content} from '../../images/categoryImages';
import {btnExperience,   btnSuperior,   btnTechnology } from '../../images/icons';

//content for service block (description all Targetiva services) on the About page
const servicesContent =  [ 
    { 
      id: 'WEBDEV',
      category: 'Web development',
      p1: "Every website that we build is designed to increase traffic to your website and to convert new visitors into consumers of the products and services that you offer. Our interactive and synergistic web design work will leave a strong visual impact on the users that view your site, generating more business activity and general interest in your brand than ever before.",
      p2: "If you are looking for a unique and one-of-a-kind website, then you have found the right marketing agency! Targetiva’s web developers know what it takes to turn an ordinary website into a truly great and functional one. We have the ability to bring forth a wide range of creative and technical solutions that will effortlessly reflect the inspirational and originative vision of your brand and bring your business to the next level.",
      img: web,
      altImg: 'web_monitor'
    },
    { 
      id: 'SEOPROMOTION',
      category: 'Search Engine Optimization',
      p1: "For companies that are relying on their own understanding when it comes to search engine optimization is regarded as one of the toughest processes to correctly implement when attempting to increase the visibility of their websites on search engine result pages. The SEO specialists at Targetiva understand what it takes to expand a company’s online presence, growing their revenue through differential SEO techniques and advanced practices.",
      p2: "Our Search Engine Optimization service includes a number of differential elements, including Technical SEO, On-Page SEO, Off-Page SEO, Local SEO, E-Commerce SEO, and Reviews Management. Targetiva has the SEO solutions that you have been searching for and though the marketplace may seem like it is overcrowded, we won’t let you get lost in the sea of consumers and brands!",
      img: seo,
      altImg: 'globe'
    },
    { 
      id: 'MARKETINGAUTOMATION',
      category: 'Data Science & Automation',
      p1: "The world of Marketing has been completely transformed from ordinary to entirely extraordinary due to the changes that have come forth through the utilization of data science, marketing automation, and technology. The successful application of marketing automation through the usage of data science has enabled popular companies and brands to grab a hold of and understand their customers behaviors and stand points, leading to focused and extremely knowledgeable insights and actions.",
      p2: "Data Science experts at Targetiva have the ability to provide an effective way for companies to collect, gain, and analyze insight from thousands of differential data points about their clients, providing them with the executive actions that need to be taken in order to successfully engage the customers that they haven’t been able to reach in the past.",
      img: automotion,
      altImg: 'robohand'
    },
    { 
      id: 'PPCSERVICES',
      category: 'Paid Advertising',
      p1: "It’s time to target your ideal clients and watch your sales soar through the roof! Paid advertising strategies include Google ads, online display ads, and social media ads through social media platforms like Facebook and Instagram, are a truly vital element that need to be accomplished in just the right way.",
      p2: " With Targetiva on your side, we will make it easy to reach your desired clients at the exact moment that they are ready to buy the products and services that you are providing. At Targetiva, our paid advertising specialists will run targeted advertisements that pertain to your company, increasing overall sales, leads, and brand awareness.",
      img: ppc,
      altImg: 'hand'
    },
    { 
      id: 'GRAPHICDESIGN',
      category: 'Branding',
      p1: "It’s time to make a lasting impression with unique and entirely relevant branding ideas and techniques that will truly make a difference in your marketing ideals. With Targetiva working alongside you, your branding techniques will allow you and your success to rise to unimaginable heights.",
      p2: "Branding is how your clients understand and experience the products and services that your organization discovers to the world. This element of marketing is extremely important as it allows your customers and potential clients to understand what they should be expecting from you as a company. The branding experts at Targetiva will work with you to build an exciting journey for your consumers to experience. Using creative thinking skills, our team will be able to express your ideas through strategies that resonate with your targeted audiences.",
      img: brand,
      altImg: 'picture'
    },
    { 
      id: 'SOCIALMEDIA',
      category: 'Social Media Marketing',
      p1: "Social media marketing is a sincerely powerful and influential way for businesses, brands, and companies of all sizes to reach customers from all around the world. Here at Targetiva, our social media marketing experts understand how to leverage your social media platforms in a truly admirable way, promoting your business and selling the products and services that you offer in creative ways.",
      p2: "We understand the process that is needed in order to create content for social media platforms and recognize what it takes to accurately promote and sell the products and services that you and your brand provide to the world. Targetiva has the ability to create meaningful and valuable content for social media platforms and through direct interaction with your customers, we work to increase brand loyalty in inconceivable ways.",
      img: smm,
      altImg: 'mobile_phone'
    },
    { 
      id: 'CONTENTPRODUCTION',
      category: 'Content Production',
      p1: "Targetiva creative professionals produce absolutely breathtaking and exciting content that makes your target audiences waiting on their next move. From strategy development to content production, publishing to distribution and promotional services.",
      p2: "Through the creation of meaningful and valuable content, Targetiva can deliver momentous and important content to a number of digital platforms, ultimately raising brand awareness and permitting your customers to interact with the content that you are posting and increasing overall loyalty to your brand. It’s time to fuel your brand with engaging content for your website, email campaigns, and social media channels.",
      img: content,
      altImg: 'tv'
    },
];
//content for FAQ (question/answer) on the About page
const faqContent = [
  {
      id: 1,
      question: 'What exactly is digital marketing?',
      answer:'Any kind of marketing effort that utilizes the usage of electronic devices is regarded as digital marketing. In direct practice, digital marketing is typically referred to as marketing strategies that are implemented through a computer, a phone, a tablet, or any other electronic device. Through these differential digital channels, companies have the ability to market or show off the products and services, in order to reach a wide range of consumers and audiences that are excited to see what the company has to offer. Targetiva incorporates the new-age digital marketing, to provide your company with the amazing results that it has been yearning for.',
  },
  {
      id: 2,
      question: 'What will a digital marketer do for my company?',
      answer:'Digital marketers are individuals that have the professionalism and skill sets to promote a business, brand, or company and the products or services that it provides by utilizing a number of different online channels. Digital marketing experts, like the professionals working at Targetiva, have the extensive knowledge and skills on how to utilize, search engines, social media, websites and online advertisements in order to generate sales leads, build an indestructible brand awareness, and increase audience traffic to your business endeavors.',
  },
  {
      id: 3,
      question: 'Would my business benefit from digital marketing services?',
      answer:'Digital marketing services have a way of putting brands and the services or products that they offer on the map in a truly inconceivable way. These new and exciting marketing services have the capability of assisting businesses in constructing an impeccable brand reputation. With Targetiva assisting you with your digital marketing endeavors, we can create trusting and exciting online personalities that will have individuals from all around the world just waiting for your very next move. Digital marketing services can create a strong relationship between brands and their customers, constructing undeniable connections between multiple individuals on various channels.',
  },
  {
      id: 4,
      question: 'How cost-effective is digital marketing when it is compared to traditional marketing techniques?',
      answer:'Digital marketing efforts are much more cost-effective when compared to traditional advertising methods. When it comes to advertising your brand on social media platforms, websites, and popular search engines, digital marketing endeavors allow companies to pay, on average, less than a dollar per individual who clicks on the particular ad that they are running on the specific digital platform. Companies, like Targetiva, will work with you to allow you and your brand to spend less of your budget while gaining more effect from the advertisements that you are running. With digital marketing, your brand has the ability to get fast and effective marketing results, gaining you instant publicity at a fraction of the cost when compared to traditional marketing efforts!',
  },
  {
      id: 5,
      question: 'How do you identify a successful digital marketing company?',
      answer:'There are several different metrics that will allow you and your brand to spot a successful and effective digital marketing company. An efficacious digital marketing company will have a sizable portfolio and will have a list of past clients that they have worked alongside of. Digital marketing companies that have brought in positive results are proud to display their past marketing partnerships with the world! Be sure to check out their past work experiences, in order to understand whether or not that company is a good fit for you. A prosperous digital marketing company will have a team of experienced and specialized team members working with them.',
  }
  ];
//content for reaso block  on the About page
  const reasonContent = [ 
    { 
      id: 1,
      title: 'Technology and Data Science',
      img: btnTechnology,
      alt: 'technology icon',
      p: "When you decide to work with Targetiva, you are working with a digital marketing company with a direct vision that will allow us to challenge the marketing purpose behind your brand. We understand what it takes to find the most efficient way to utilize the technological advancements that have been created in the world of digital marketing and hold the skill sets that allow us to operate robust technology, creating data-driven marketing techniques that will surely bring you the results that you have been waiting for. We utilize data science to understand what exactly you need from our company to become the brand you were always meant to be.",      
    },
    { 
      id: 2,
      title: 'Experience and Knowledge',
      img: btnExperience,
      alt: 'experience icon',
      p: "Targetiva provides brands with experience and knowledge unlike any other marketing partner. Our passion is to understand the way that your clients think, feel and reason, which permits us to create a strong bond between the content that we create and the consumers that you want to purchase the goods and services that you can provide to them.",      
    },
    { 
      id: 3,
      title: 'Superior Customer Service',
      img: btnSuperior,
      alt: 'Superior Customer Service icon',
      p: "Our customer service is truly impeccable as we listen to all of our clients wants and needs. It’s time to make an impression that lasts, with Targetiva working alongside you for all of your digital marketing needs.",   
    }
  ];


    export {servicesContent, faqContent, reasonContent};

import React, {useState} from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import {servicesContent} from './content';
import icons from '../Icons/serviceIcons';
import {MOBILE, MOBILE_L, TABLET } from '../../constants/responsive';
import Angle from '../Icons/Angle';


const ServiceBlockContainer = styled('section')`
  display: flex;
  flex-direction: column;    
  gap: 4rem;
  text-align: left;
  @media (max-width:${MOBILE}) {
    gap:0.8rem;
  }; 
`;

const ServiceContainer = styled('div')`
  display: flex;
  flex-direction: row;
  gap:3.2rem;
  @media (max-width:${TABLET}) {
    gap:1.6rem;
  }; 
`;

const ButtonsContainer = styled('div')`
  display: flex;
  flex-direction: column;
  gap:8px;
  flex: 33%;
  @media (max-width:${TABLET}) {
    flex: 40%;
  };
`;

const Button = styled('button')`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap:1rem;
  padding: 1.6rem 0.8rem;
  font-size: 1.4rem;
  line-height: 140%;
  color: var(--bg-color-red);
  background: var( --bg-color-grey);
  border: none;
  border-radius: 4px;
  h3 {
    color:var(--main-color-black);
    font-size: 1.8rem;
  } 
  span {
    color:var(--main-color-black);
  }
  @media (max-width:${MOBILE_L}) {
    width: 100%;
    span {
    color:var(--main-color-black);
    };
    h3 {
      width: 100%;
      text-align: start;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 140%;
    };
  };   
`;

const activeButton = css`
  background:var(--bg-color-red)!important;
  color:var(--main-color-white)!important;
  h3 {
    color:var(--main-color-white)!important;
  };
  @media (max-width:${MOBILE}) {
    span {
      color:var(--main-color-white)!important;
    };
  };       
`;

const activeAngle = css`
  transform: rotate(180deg);    
`;

const ServiceContent = styled('div')`
  display:flex;
  flex-direction: row;
  padding: 3.2rem 0 3.2rem 2.4rem;
  background: var( --bg-color-grey);
  flex: 67%;
  gap: 4.8rem;
  border-radius: 8px;
  @media (max-width:${TABLET}) {
    flex: 60%;
    flex-direction: column;
  };
  @media (max-width:${MOBILE}) {
    gap: 4rem;
    padding: 3.2rem 0 3.2rem 1.6rem;
  }; 
`;

const ServiceContentText = styled('div')`
  flex: 60%;
  @media (max-width:${TABLET}) {
    padding-right:1.6rem;
  }; 
`;

const ServiceImage = styled('div')`
  display:flex;
  justify-content: center;
  align-items: center;
  flex: 40%;
  @media (max-width:${TABLET}) {
    justify-content: start;
    #robohand, #hand {
      margin-left:auto;
    };
  }; 
`;

const Line = styled('div')`
  background-color: var(--bg-color-red);
  width: 79px; 
  height: 2px;
  margin-bottom:1.6rem;
`;

const categories = [ 
  'WEBDEV',
  'SEOPROMOTION',
  'MARKETINGAUTOMATION',
  'PPCSERVICES',
  'GRAPHICDESIGN',
  'SOCIALMEDIA',
  'CONTENTPRODUCTION'
];

const categoryMapper = {
  'WEBDEV': 'Web Development',
  'SEOPROMOTION': 'Search Engine Optimization',
  'MARKETINGAUTOMATION': 'Data Science & Automation',
  'PPCSERVICES': 'Paid Advertising',
  'GRAPHICDESIGN': 'Branding',
  'SOCIALMEDIA': 'Social Media Marketing',
  'CONTENTPRODUCTION': 'Content Production'
};

function ServiceBlock() {
  const breakpoints = useBreakpoint(); 
  const [category, setCategory] = useState(null);
  const [activeId, setActiveId] = useState(servicesContent[0].id);
  const onCategoryChange = (categ) => {
    if (categ === category) return;
    setCategory(categ);  
  };
  const handleClick = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }
  return (
    <ServiceBlockContainer>
      <h2 className='h2'>Digital Marketing Services We Provide</h2>
      {!breakpoints.xs&&
      <ServiceContainer>
        <ButtonsContainer>
          {categories.map((category) => (
          <Button onClick={() => {onCategoryChange(category); setActiveId(category)}} 
            key={category} 
            className={activeId===category?activeButton: undefined}
            >            
            {icons[category]}
            <h3>{categoryMapper[category]}</h3>
          </Button>))
        }  
        </ButtonsContainer>
        <>
          {activeId && servicesContent.map(item => ((item.id === activeId) && 
            <ServiceContent key={item.id}>
              <ServiceContentText >
                <Line/>
                <p>{item.p1}</p><br/>
                <p>{item.p2}</p>
              </ServiceContentText>
              <ServiceImage >
                <img src={item.img} alt={item.altImg} />
              </ServiceImage>
            </ServiceContent>
          ))}
        </>
      </ServiceContainer> 
      }
      {breakpoints.xs&&servicesContent.map(item => (
        <AccordionMobile item = {item} isActive={item.id === activeId} onClick={handleClick} key={item.id} />
      ))}
    </ServiceBlockContainer>
  )
};

export default ServiceBlock;

function AccordionMobile({item, onClick, isActive}) {
  const handleClick = () => {
    onClick(item.id);
  }  
  return (    
    <>      
      <Button onClick={handleClick} type='button' className={isActive?activeButton: undefined} >
        {icons[item.id]}<h3>{item.category}</h3><span className={isActive ? activeAngle: undefined}><Angle/></span>               
      </Button>
      <div className={isActive ? 'active-accordion' : 'hidden-accordion'}>
      <ServiceContent className={isActive ? 'active-accordion-inner ' : 'hidden-accordion-inner'}>
        <ServiceContentText >
          <Line/>
          <p>{item.p1}</p><br/>
          <p>{item.p2}</p>
        </ServiceContentText>
        <ServiceImage >
          <img src={item.img} alt={item.altImg} id={item.altImg} />
        </ServiceImage>
      </ServiceContent>        
      </div>
    </>
  );
};


     


import styled from '@emotion/styled';
import React, { useState } from 'react';
import trustpilotLogo from '../../images/trustpilot-logo.svg';
import trustpilotStars from '../../images/trustpilot-stars.svg';
import btnNextB from '../../images/icons/btn-review-black-next.svg';
import btnNextW from '../../images/icons/btn-review-white-next.svg';
import btnPrevB from '../../images/icons/btn-review-black-prev.svg';
import btnPrevW from '../../images/icons/btn-review-white-prev.svg';
import { MOBILE, MOBILE_L, TABLET } from '../../constants/responsive';
import { PrimaryBtnLink } from '../common';

const reviews = [{
  label: '',
  text: "I wouldn't say I like writing reviews, but Targetiva is a special one. They optimized my site for google and offered SEO package. Well, I am old school guy and don't like digital stuff...long story short, after 2 months, my business is top 3 results on google maps. I don't know how they did it but I love it. Great company.",
},
{
  label: '',
  text: 'I was looking for a digital marketing agency for local SEO. Targetiva had good reviews and they also replied to my email quickly. Alex emailed me the quote and I signed the contract with them. It was one of the best decisions I made. They send me reports every month to show the progress. After 3 months of work, they moved my construction to the top 5 results on google maps.',
},

];
const Container = styled('div')`
  display:flex;
  flex-direction:column;
  gap:4rem;
  & >a {
    margin:0 auto;
  }
`;

const ReviewsContainer = styled('div')`
    display: flex;
    flex-direction: row;
    border-top: 1px solid rgba(54, 54, 54, 0.2);
    width:100%;
    @media (max-width:${MOBILE}) {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-areas: 'text text' 'logo btn' 
        
        }
`;
const ReviewLogo = styled('div')`
    flex: 1 27.1rem;
    border-right:1px solid rgba(54, 54, 54, 0.2);
    display: grid;
    gap: 8px;
    place-content: center;
    padding: 0 4rem 0 1.8rem;
    & img {
        width:12rem
      }
    @media (max-width:${TABLET}) {
      flex: 1 20rem;     
        }
    @media (max-width:${MOBILE}) {
        border-right:0;
        grid-area: logo;
        place-content: normal;
        padding: 0;
        }
`;
const ReviewContainer = styled('div')`
    
    display: flex;
    flex-direction: column;
    padding: 2.4rem;
    @media (max-width:${MOBILE_L}) {
      padding: 1.6rem;    
        }
    @media (max-width:${MOBILE}) {
      grid-area: text;
      padding: 1.2rem 0;
        }
`;
const Stars = styled('div')`
`;
const TextReview = styled('div')`
        margin-top: 1.6rem;
        min-height: 9rem;          
`;
const Buttons = styled('div')`
   
    display: flex;
    flex-direction:row;
    gap:3rem;
    button {
        background: var(--main-color-white);
        border: 0;
        padding: 0;
    }
    @media (max-width:${MOBILE}) {
      grid-area: btn;  
      justify-self: end;
      align-self: end;  
      gap:2rem;  
        }
`;
const ButtonPrev = styled('button')`
    &:after {
      background-image: url(${btnPrevW});
      background-size: 48px;
      display: inline-block;
      width: 48px; 
      height: 48px;
      content:"";       
    };
    &:active:after {
      background-image: url(${btnPrevB});        
    };
    @media (max-width:${MOBILE}){
      &:after {        
      background-size: 32px;
      display: inline-block;
      width: 32px; 
      height: 32px;      
    };
    }
    
`;
const ButtonNext = styled('button')`
    &:after {
      background-image: url(${btnNextB});
      background-size: 48px;
      display: inline-block;
      width: 48px; 
      height: 48px;
      content:"";
    }
    &:active:after {
      background-image: url(${btnNextW});
        
    };
    @media (max-width:${MOBILE}){
      &:after {        
      background-size: 32px;
      display: inline-block;
      width: 32px; 
      height: 32px;
      
    };
    }
`;
function Trustpilot() {
  const [currentIndex, setCurrentIndex] = useState(0);
  const onPrevReview = () => {
    const isFirstReview = currentIndex === 0;
    const newIndex = isFirstReview ? reviews.length - 1 : currentIndex - 1;
    setCurrentIndex(newIndex);
  };
  const onNextReview = () => {
    const isLastReview = currentIndex === reviews.length - 1;
    const newIndex = isLastReview ? 0 : currentIndex + 1;
    setCurrentIndex(newIndex);
  };
  
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  function handleTouchStart(e) {
      setTouchStart(e.targetTouches[0].clientX);
  };

  function handleTouchMove(e) {
      setTouchEnd(e.targetTouches[0].clientX);
  };

  function handleTouchEnd() {
    if (touchStart - touchEnd > 50) {          
        onNextReview();
    };

    if (touchStart - touchEnd < -50) {          
        onPrevReview();
    };
  };
  return (
    <Container>
      <h2 className="h2">Reviews</h2>
      <ReviewsContainer>
        <ReviewLogo>
          <img src={trustpilotLogo} alt="trustpilot logo" />
          <Stars><img src={trustpilotStars} alt="trustpilot stars" /></Stars>
        </ReviewLogo>
        <ReviewContainer>
          <TextReview>
            <p
             onTouchStart={handleTouchStart}
             onTouchMove={handleTouchMove}
             onTouchEnd={handleTouchEnd}>
              {reviews[currentIndex].text}
            </p>
          </TextReview>
        </ReviewContainer>
        <Buttons>
          <ButtonPrev onClick={onPrevReview} />
          <ButtonNext onClick={onNextReview} />
        </Buttons>
      </ReviewsContainer>
      <PrimaryBtnLink btnLabel="Read more" href="https://www.trustpilot.com/review/targetiva.com" onTarget />
    </Container>
  );
}

export default Trustpilot;

import styled from '@emotion/styled';
import React, {useState} from 'react';
import { css } from '@emotion/css';
import { reasonContent } from './content';
import {MOBILE, MOBILE_L, TABLET } from '../../constants/responsive';

const ReasonBlockContainer = styled('section')`
  display: flex;
  flex-direction: column;
  gap: 4rem;
  text-align: left;
  width: 80%;
  align-self: flex-start;
  @media (max-width:${TABLET}) {
    width: 100%;
  };  
`;
const ReasonConatiner = styled('div')`
  display: flex;
  flex-direction: column;
  gap: 1.6rem;
`;
const ButtonsContainer = styled('div')`
  position:relative;
  display: grid;
  grid-template-columns: 179px 179px 179px 1fr;
  gap: 2.6rem;
  border-bottom: 3px solid var( --main-color-grey);
  max-width: 90%;
  padding-bottom:8px;
  @media (max-width:${MOBILE_L}) {
    grid-template-columns: 1fr 1fr 1fr;
    max-width: fit-content;
    width: 100%;
    gap: 2.4rem;
  };
`;
const Button = styled('button')`
  width: 179px;
  height: 65px;
  display:flex;
  gap: 5px;
  flex-direction:column;
  align-items:center;
  border: none;
  padding: 0;
  margin: 0;
  background:var( --bg-color-white);
  @media (max-width:${MOBILE_L}) {
  	width: fit-content;
  	height: auto;
  };
  h3 {
    color:var(--main-color-black);
  }
`;
const active =css`
  &::after {
    content: '';        
    height: 3px;
    background-color: var( --bg-color-red);
    width: 179px;
    position:absolute;
    bottom: -3px;
  };
  @media (max-width:560px) {
    &::after {       
    width: 130px;       
    };
  };
  @media (max-width:${MOBILE}) {
  	&::after {       
 			width: 100px;     
    };
  };
`;
const ContentText = styled('p')`
  min-height: 14rem;
  @media (max-width:${MOBILE_L}) {
    min-height: 17rem;
  };
  @media (max-width:${MOBILE}) {
  	min-height: 18rem;
  };
`;

function ReasonBlock() {
	const [activeId, setActiveId] = useState(reasonContent[0].id);
  const [touchStart, setTouchStart] = React.useState(0);
  const [touchEnd, setTouchEnd] = React.useState(0);

  function handleTouchStart(e) {
      setTouchStart(e.targetTouches[0].clientX);
  };
  function handleTouchMove(e) {
      setTouchEnd(e.targetTouches[0].clientX);
  };

  function handleTouchEnd(id) {
    if (touchStart - touchEnd > 75) { 
      if (id >= reasonContent[reasonContent.length-1].id) return;                
      setActiveId(id +1);
    };
    if (touchStart - touchEnd < -75) {
      if (id <= reasonContent[0].id) return;               
      setActiveId(id - 1);
    };
  };
  return (
    <ReasonBlockContainer>
    	<h2 className='h2'>Why You Should Work<br/> with Digital Marketing Agency Targetiva?</h2>
    	<p>There are a multitude of digital marketing agencies that promise to deliver incredible marketing results to companies that are 	looking to exceed their marketing expectations and reach an entirely new group of audience segments.<br/><br/>
    	However, Targetiva has the ability to truly surpass any and all of a brand’s beliefs and goals. Our knowledgeable and skillful marketing experts are passionate about the work that we produce, and every project we take on. Here at Targetiva, we prioritize connection between brands and their consumers.</p>
			<ReasonConatiner>
				<ButtonsContainer>
					{reasonContent.map(item => (
						<Button onClick = {() => setActiveId(item.id)} className={activeId===item.id?active:undefined} key={item.id}>
							<img src={item.img} alt={item.alt} />
							<h3 className='additional'>{item.title}</h3>
						</Button>
					))}
				</ButtonsContainer>
					{activeId && reasonContent.map((item) => ((item.id === activeId) &&  
						<ContentText key={item.id}
              onTouchStart={handleTouchStart}
              onTouchMove={handleTouchMove}
              onTouchEnd={() =>handleTouchEnd(item.id)}
            >
							{item.p}
						</ContentText>))}
			</ReasonConatiner>
    </ReasonBlockContainer>
  );
};

export default ReasonBlock;
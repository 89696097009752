import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { Formik, Field, Form } from 'formik';
import * as Yup from 'yup';
import { PrimaryBtn, SelectCustom } from '../common';
import { MOBILE } from '../../constants/responsive';
import { optionsListServices } from '../../constants/targetivaInfo';
import useSendForm from '../../utils';

const themeColor = 'var(--main-color-black)';
const bgColor = 'var(--main-color-white)';
const StyledForm = css`
    display: flex;
    flex-direction:column;
    row-gap:2.4rem;
    max-width:56.9rem;
    width:100%;
    align-items:center;
    font-family: inherit;
    @media (max-width:${MOBILE}) {
        width:90%;
          }
`;
const StyledInput = styled('input')`
  box-sizing: border-box;
   width:100%;
   height: 5rem;
   border: 2px solid ${themeColor};
   border-radius: 8px;
   background: var(--main-color-white);
   text-align: center;
   color:${themeColor};
   font-size: 16px;
  line-height: 140%;
   &:focus {
    border: 2px solid ${themeColor};
    outline: none;
   };
   &::placeholder{
    color:${themeColor};
    font-size: 16px;
    line-height: 140%
   };
   &:focus::placeholder{
    color:${themeColor};
   }
`;
const Error = styled('div')`
  font-size:1.4rem;
  color:red;
  margin-top:-2rem;
`;
const phoneRegExp = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const GetConsultationSchema = Yup.object({
  name: Yup.string()
    .max(20, 'Must be 20 characters or less')
    .required('Required'),
  business: Yup.string()
    .required('Required'),
  email: Yup.string()
    .email('Invalid email address')
    .required('Required'),
  phone: Yup.string().matches(phoneRegExp, 'Invalid phone number'),
  services: Yup.string()
    .required('Please, choose one of the services category '),
});
function ServiceSelect({ field, form, options}) {
  return <SelectCustom themeColor={themeColor} optionsList={options} placeholder="Services" form={form} field={field} bgColor={bgColor}/>;
}

function ConsultationForm({ navigate }) {
  const onSubmitForm = useSendForm(navigate);
  return (        
      <Formik
        initialValues={{
          services: '',
          business: '',
          name: '',
          email: '',
          phone: '',
        }}
        onSubmit={onSubmitForm}
        validationSchema={GetConsultationSchema}
      >
        {({ errors, touched, isSubmitting }) => (
          <Form className={StyledForm} id="consultation_form-black">
            <Field id="services" name="services" component={ServiceSelect} options={optionsListServices} />
            {errors.services && touched.services ? (
              <Error>{errors.services}</Error>) : null}
            <Field id="business" name="business" placeholder="Business Niche" as={StyledInput} />
            {errors.business && touched.business ? (
              <Error>{errors.business}</Error>) : null}
            <Field id="name" name="name" placeholder="Your name" as={StyledInput} />
            {errors.name && touched.name ? (
              <Error>{errors.name}</Error>) : null}
            <Field
              id="email"
              name="email"
              placeholder="Email address"
              type="email"
              as={StyledInput}
            />
            {errors.email && touched.email ? (
              <Error>{errors.email}</Error>) : null}
            <Field id="phone" name="phone" placeholder="Phone number" type="tel" as={StyledInput} />
            {errors.phone && touched.phone ? (
              <Error>{errors.phone}</Error>) : null}
            <PrimaryBtn btnLabel="Submit" disabled={isSubmitting} />
          </Form>
        )}
      </Formik>   
  );
}

export default ConsultationForm;

import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/css';
import { MOBILE } from '../../constants/responsive';


const AccordionItem = styled('button')`
  position:relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: inherit;
  border: 0;
  border-top: 2px solid var(--main-color-black);
  border-bottom: 2px solid var(--main-color-black);
  cursor: pointer;
  padding: 16px 0;
  width: 100%;
  text-align: left;
  outline: none;    
  `;
const nonBorder = css`
  border-bottom: none !important;          
`;

const borderNone = css`
  button {
      border-bottom: none !important;
  }    
`;
const Panel = styled('div')` 
  
  background-color: white;  
  box-sizing: border-box;
       
`;
const AccordionBtn = styled('div')`
  position: absolute;
  top: 45%;
  right: 13px;
  pointer-events: none;
  transform: translate(0, 45%);
  & div {
    position: absolute;
    left: 50%;   
    top: 50%;
    background-color: var(--bg-color-red);
    border-radius: 2px;
  };
`;
const Horizontal = styled('div')`
  width: 24px;
  margin-left: -10px;    
  opacity: 1;    
  height: 5.6px;    
`;
const Vertical = styled('div')`
  height: 24px;
  margin-top: -9px; 
  width: 5.6px;    
  margin-left: -1px;     
`;
const activeRotateVertical = css`
  transition: all .5s ease-in-out;
  transform: rotate(-90deg);
`;
const activeRotateHorizontal = css`
  transition: all .5s ease-in-out;
  transform: rotate(-180deg);
  opacity: 0;
`;
const backRotate = css`
  transition: all .5s ease-in-out;
  transform: rotate(90deg);
`;
const P =styled('p')`
  font-size: 2rem;
  line-height: 140%;
  margin-bottom:1.6rem;
  @media (max-width:${MOBILE}) {
    font-size: 1.4rem;
  }
`;

function Accordion({ isBorder, title, text, onClick, isActive, id }) {
  const handleClick = () => {
    onClick(id);
  }

  return (
    <div className={isBorder && borderNone}>
      <AccordionItem onClick={handleClick} className={isActive && nonBorder} type='button'>
        <span className="h2">{title}</span>
        <AccordionBtn>        
          <Horizontal className={isActive ? activeRotateHorizontal : backRotate}/>
          <Vertical className={isActive ? activeRotateVertical : backRotate}/>
        </AccordionBtn>       
      </AccordionItem>       
      <Panel className={isActive ? 'active-accordion' : 'hidden-accordion'}>
        <P className={isActive ? 'active-accordion-inner ' : 'hidden-accordion-inner'}>{text}</P>
      </Panel>
      
    </div>
  );
}

export default Accordion;




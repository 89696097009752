import React, { useState } from 'react';
import AccordionItem from './AccordionItem';

const Accordion = ({ items }) => {
  const [activeId, setActiveId] = useState(null);
  const handleClick = (id) => {
    if (activeId === id) {
      setActiveId(null);
    } else {
      setActiveId(id);
    }
  }
  return (
    <>
      {items.map(item => (
        <AccordionItem {...item} isActive={item.id === activeId} onClick={handleClick} key={item.id} />
      ))}
    </>
  )
}

export default Accordion;
